<template>
  <div
    v-on-click-outside="onClickOutside"
    :class="{ opened, hasValue }"
    data-component-name="AppDropdown"
  >
    <div class="wrapper">
      <div
        :class="['fake-input', fill]"
        @click="setOpened(!opened)"
      >
        <label v-if="label">
          {{ label + (required ? '*' : '') }}
        </label>

        <span v-if="hasValue" class="value">
          {{
            optionAttribute && (modelValue !== null && typeof modelValue === 'object')
              ? modelValue[optionAttribute]
              : modelValue
          }}
        </span>

        <button type="button" :aria-label="`Show ${label.toLocaleLowerCase()} options`">
          <SvgChevronDown />
        </button>
      </div>

      <transition name="fade">
        <AppDropdownList
          v-if="opened"

          v-model="modelValue"

          :options
          :optionAttribute

          :searchable
          :searchablePlaceholder
          :searchAttributes
        />
      </transition>
    </div>

    <span v-if="error" class="error">
      {{ error }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import AppDropdownList from './AppDropdownList.vue';
import type { ModelValue, AppDropdownProps, AppDropdownListProps } from './types';

const modelValue = defineModel<ModelValue>('modelValue', {
  default: () => ({}),
});

const props = withDefaults(
  defineProps<AppDropdownProps & AppDropdownListProps>(),
  {
    required: false,
    fill: 'solid',
  },
);

const opened = ref(false);
const setOpened = (value: boolean) => {
  opened.value = value;
};

const hasValue = ref(false);
const setHasValue = (value: boolean) => {
  hasValue.value = value;
};

const onClickOutside = () => {
  if (opened.value) {
    setOpened(false);
  }
};

watch(modelValue, (newValue) => {
  setOpened(false);
  nextTick(() => setHasValue(Boolean(newValue)));
}, { immediate: true });
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/common";
@import "$/mixins/flex";
@import "$/functions/token";
@import "$/variables/shadows";

[data-component-name="AppDropdown"] {
  width: 100%;
  min-width: 12.25rem;

  user-select: none;
  position: relative;

  border: 1px solid transparent;

  .wrapper {
    position: relative;

    .fake-input {
      position: relative;
      cursor: pointer;

      @include flex-center-sb;
      gap: 1rem;

      padding: 1rem;
      background-color: token('surf-cont-secondary');
      border: 1px solid transparent;

      transition: all 0.15s ease;

      &.outline {
        border-color: token('outline-primary');
      }

      &:hover {
        background-color: rgba(token('accent-hover'), 16%);
      }

      &:active {
        background-color: rgba(token('accent-hover'), 24%);
      }

      label {
        cursor: inherit;
        @include body-3;
        color: token('text-secondary');

        position: absolute;
        padding: 0 0.25rem;

        bottom: 50%;
        transform: translateY(50%);
      }

      span.value {
        color: token('text-primary');
        @include body-3;
      }

      button {
        margin-left: auto;
        @include fixed-size(1.5rem);

        svg {
          @include full-size;
          transition: transform 0.15s ease;
        }
      }
    }

    [data-component-name="AppDropdownList"] {
      position: absolute;
      left: -0.5rem;
      top: 0.5rem;
      right: -0.5rem;
      z-index: 2;
    }
  }

  &.opened {
    .wrapper {
      .fake-input {
        border-color: token('outline-action');
        background-color: rgba(token('accent-hover'), 16%);
      }
    }
  }

  &.opened,
  &.hasValue {
    .wrapper {
      .fake-input {
        label {
          @include caption;

          bottom: 100%;
          background-color: token('surf-cont-primary');
        }
      }
    }
  }

  &:has(.error) {
    border: 1px solid token('outline-error');
    margin-bottom: 1.25rem;

    .error {
      @include caption;
      color: token('error');

      position: absolute;
      left: 1rem;
      top: calc(100% + 0.25rem);
    }
  }
}
</style>
